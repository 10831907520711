import React, { useState } from "react";
// import Toast from "../toast/toast";
import Input from "../input/input";

// import checkIcon from "../../../images/svg/check.svg";
// import errorIcon from "../../../images/svg/error.svg";

const Form = ({ initialForm, className, name, dataNetlify }) => {
  // const [list, setList] = useState([]);
  const [contactForm, setContactForm] = useState(initialForm);
  const [formIsValid, setFormIsValid] = useState(false);
  // const [loading, setLoading] = useState(false);
  const formElementsArray = [];

  for (let key in contactForm) {
    formElementsArray.push({
      id: key,
      config: contactForm[key],
    });
  }

  let form = (
    <form
      name={name}
      className={className}
      onSubmit={(event) => submitForm(event)}
      data-netlify={dataNetlify}
      action="/success"
      method="POST"
    >
      <input type="hidden" name="form-name" value={name} />
      {formElementsArray.map((formElement, i) => (
        <Input
          id={formElement.id}
          name={formElement.config.name}
          key={formElement.id}
          elementType={formElement.config.elementType}
          value={formElement.config.value}
          valid={formElement.config.valid}
          touched={formElement.config.touched}
          labelConfig={formElement.config.labelConfig}
          elementConfig={formElement.config.elementConfig}
          classConfig={formElement.config.classConfig}
          validationConfig={formElement.config.validationConfig}
          onChange={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <input className="input-element" type="submit" value="Submit" />
    </form>
  );

  // if (loading) {
  //   form = "TEST";
  // }

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedContactForm = {
      ...contactForm,
    };
    const updatedFormElement = {
      ...updatedContactForm[inputIdentifier],
    };

    if (updatedFormElement.elementConfig.type === "checkbox") {
      updatedFormElement.checked = event.target.checked;

      updatedFormElement.valid = checkValidity(
        updatedFormElement.checked,
        updatedFormElement.validationConfig
      );
    } else {
      updatedFormElement.value = event.target.value;

      updatedFormElement.valid = checkValidity(
        updatedFormElement.value,
        updatedFormElement.validationConfig
      );
    }
    updatedFormElement.touched = true;

    updatedContactForm[inputIdentifier] = updatedFormElement;

    let isValid = true;

    for (let inputIdentifier in updatedContactForm) {
      isValid = updatedContactForm[inputIdentifier].valid && isValid;
    }

    setContactForm(updatedContactForm);
    setFormIsValid(isValid);
  };

  const checkValidity = (value, validationConfig) => {
    let isValid = true;

    if (validationConfig.isCheckbox) {
      isValid = value;
      return value;
    }

    if (validationConfig.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (validationConfig.minLength) {
      isValid = value.length >= validationConfig.minLength && isValid;
    }

    if (validationConfig.maxLength) {
      isValid = value.length <= validationConfig.maxLength && isValid;
    }

    if (validationConfig.isEmail) {
      const email = value.toUpperCase();
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
      isValid = re.test(email) && isValid;
    }

    return isValid;
  };

  const submitForm = (event) => {
    if (formIsValid) {
      return true;
    } else {
      event.preventDefault();

      const updatedContactForm = {
        ...contactForm,
      };

      let isValid = true;

      for (let inputIdentifier in updatedContactForm) {
        if (!updatedContactForm[inputIdentifier].valid) {
          updatedContactForm[inputIdentifier].touched = true;
        }
        isValid = updatedContactForm[inputIdentifier].valid && isValid;
      }

      setContactForm(updatedContactForm);
    }
  };

  // const showToast = (type) => {
  //   const id = Math.floor(Math.random() * 100 + 1);
  //   let toastProperties = null;
  //   switch (type) {
  //     case "success":
  //       toastProperties = {
  //         id,
  //         title: "Success",
  //         message: "Your message was sent!",
  //         backgroundColor: "#5cb85c",
  //         icon: checkIcon,
  //       };
  //       break;
  //     case "error":
  //       toastProperties = {
  //         id,
  //         title: "Error",
  //         message: "Your message could not be sent!",
  //         backgroundColor: "#d9534f",
  //         icon: errorIcon,
  //       };
  //       break;
  //     default:
  //       setList([]);
  //   }
  //   setList([...list, toastProperties]);
  // };
  // <Toast toastList={list} autoDelete={true} autoDeleteTime={5000} />
  return form;
};

export default Form;
