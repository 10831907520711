import React from "react";

const Input = ({
  id,
  name,
  elementType,
  value,
  valid,
  touched,
  labelConfig,
  elementConfig,
  classConfig,
  validationConfig,
  onChange,
}) => {
  let input = null;
  let label = null;
  let inputElement = null;
  let validation = null;
  const showLabel = labelConfig.required;
  const showValidation = validationConfig.required && !valid && touched;
  let inputClasses = classConfig.classes;
  let errorClasses = ["validation-message"];

  const pushClass = (className, classArray) => {
    if (classArray.indexOf(className) === -1) {
      classArray.push(className);
    }
  };

  if (inputClasses == null) {
    inputClasses = ["input-element"];
  } else {
    pushClass("input-element", inputClasses);
  }

  if (showValidation) {
    pushClass("show-validation", inputClasses);
    pushClass("show-validation", errorClasses);
  }

  label = showLabel ? (
    <label htmlFor={id} key={id + "-label"} id={id + "-label"}>
      {labelConfig.text}
    </label>
  ) : null;

  validation = showValidation ? (
    <span
      key={id + "-validation"}
      id={id + "-validation"}
      className={errorClasses.join(" ")}
    >
      {validationConfig.errorText}
    </span>
  ) : null;

  switch (elementType) {
    case "input":
      pushClass("standard", inputClasses);
      inputElement = (
        <input
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...elementConfig}
          className={inputClasses.join(" ")}
          onBlur={onChange}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...elementConfig}
          className={inputClasses.join(" ")}
          onBlur={onChange}
        />
      );
      break;
    case "select":
      pushClass("standard", inputClasses);
      inputElement = (
        <select
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={inputClasses.join(" ")}
          onBlur={onChange}
        >
          {elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      pushClass("standard", inputClasses);
      inputElement = (
        <input
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...elementConfig}
          className={inputClasses.join(" ")}
          onBlur={onChange}
        />
      );
  }

  input = (
    <div id={id + "-container"}>
      {label}
      {inputElement}
      {validation}
    </div>
  );
  return input;
};

export default Input;
