import * as React from "react";

import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";
import Form from "../components/ui/form/form";

const Submit = () => {
  const initialForm = {
    projectTitle: {
      name: "Project Title",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Project Title",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter your Project Title",
      },
    },
    description: {
      name: "Description",
      elementType: "textarea",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Description",
      },
      elementConfig: {
        rows: 3,
        placeholder: "",
      },
      classConfig: {
        classes: ["no-resize", "full-border"],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter your project description",
      },
    },
    weTransferLink: {
      name: "WeTransfer Link",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "WeTransfer Link",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: false,
        errorText: "",
      },
    },
    copyright: {
      elementType: "input",
      value: "agreed",
      valid: false,
      touched: false,
      checked: false,
      labelConfig: {
        required: true,
        text: "I agree the submitted work is not in violation of any copyright or non-disclosure agreement",
      },
      elementConfig: {
        type: "checkbox",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        isCheckbox: true,
        errorText: "Please agree to the terms",
      },
    },
    name: {
      name: "Name",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Name(s)",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        errorText: "Please enter your name",
      },
    },
    email: {
      name: "Email",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Email",
      },
      elementConfig: {
        type: "email",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: true,
        isEmail: true,
        errorText: "Please enter a valid email address",
      },
    },
    instagram: {
      name: "Instagram",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Instagram",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: false,
        errorText: "",
      },
    },
    location: {
      name: "Location",
      elementType: "input",
      value: "",
      valid: false,
      touched: false,
      labelConfig: {
        required: true,
        text: "Location",
      },
      elementConfig: {
        type: "text",
        placeholder: "",
      },
      classConfig: {
        classes: [],
      },
      validationConfig: {
        required: false,
        errorText: "",
      },
    },
  };

  return (
    <Layout>
      <Seo title="Submit" />
      <div className="padded">
        <h1 className="page-title">Submit</h1>
        <Form
          name={"Submit Form"}
          initialForm={initialForm}
          className={"form submit-form"}
          dataNetlify={true}
        ></Form>
      </div>
    </Layout>
  );
};

export default Submit;
